.App {
  background: rgba(255, 255, 255, 0);
  font-family: "Roboto";
}

body {
  overflow: hidden;
}

.notransition {
  transition: none !important;
}

.container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  transition: all 0.7s ease-in-out 0s;
  /* display: grid;
  grid-template-areas: "header" "main" "footer";
  grid-gap: 0; */
  display: flex;
  flex-direction: column;
}

.header {
  grid-area: header;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  justify-items: center;
}

.left {
  padding: 20px 10px;
  background-color: #464646;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

.filter-list-container {
  width: 100%;
  max-height: 65%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 25px;
}

.image-controls-container {
  width: 100%;
  max-height: 35%;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
}

.filter-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 220px;
  justify-content: space-evenly;
}

.editor {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 50px 0;
  width: calc(100vw - 300px);
}

.main {
  grid-area: main;
  display: flex;
  flex-direction: row;
  height: calc(100vh - var(--header-height));
}

.footer {
  grid-area: footer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 12px;
  background: #686868;
}

.footer-button-group {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  /* width: 350px; */
}

.footer a {
  text-decoration: none;
  color: white;
  padding: 3px 5px 3px 5px;
}

.dimmer {
  position: absolute;
  z-index: 10000;
  background: #fff9;
  width: 100vw;
  height: 100vh;
}

.lightbox {
  box-shadow: 10px 10px 7px 0 rgba(37, 37, 37, 0.75);
  top: 5vh;
  right: 15vw;
  position: absolute;
  width: 50vw;
  height: 70vh;
  transition: all 0.7s ease-in-out 0s;
  display: grid;
  grid-template-rows: 1fr 10fr;
  grid-template-columns: 1fr;
  grid-template-areas: "lbheader lbheader" "lbmain lbmain ";
  grid-gap: 0;
  border-radius: 10px;
  font-family: PoetsenOne;
  font-size: larger;
  user-select: none;
}

.lbheader {
  background: linear-gradient(
    to right,
    rgba(37, 37, 37, 0.9),
    rgba(37, 37, 37, 1)
  );
  grid-area: lbheader;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 5px 5px 0px 0px;
}

.lbheader > span {
  user-select: none;
}

.lbheader > span :hover {
  cursor: pointer;
}

.lbmain {
  grid-area: lbmain;
  background-color: #c7c7c7;
  background-image: linear-gradient(
      to bottom,
      rgba(228, 228, 228, 0.3),
      rgba(228, 228, 228, 1)
    ),
    url("../images/bg_pattern_white.svg");
  background-size: 300%;
  background-position: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 0px 0px 5px 5px;
  color: #686868;
}
.bab-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
}

.bab-container + .footer #aboutus {
  display: none;
}

.bab-container .upload-button {
  width: 100px;
}

.shared {
  height: calc(100vh - var(--header-height));
  display: grid;
  grid-template-rows: 1fr 60vh 1fr;
  grid-template-columns: 1fr 3fr 1fr;
  grid-template-areas: "leftshare top right" "leftshare center right" "leftshare bottom right";
  position: relative;
}

.center {
  grid-area: center;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
}

.right {
  grid-area: right;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom {
  grid-area: bottom;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.leftshare {
  grid-area: leftshare;
}

#instashare-advert {
  grid-area: header;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to bottom left, #5b00c4, #d00049);
  height: 80px;
  width: 350px;
  cursor: pointer;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.top label {
  font-family: PoetsenOne;
  font-size: larger;
  padding-right: 15px;
}

.photo {
  max-width: 100%;
  max-height: 100%;
}

.share-footer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: baseline;
  width: 450px;
}

.share-url {
  overflow: hidden;
  border-radius: 3px;
  background-color: #c7c7c7;
  color: #fbfbfb;
  border: none;
  margin: 20px 0;
  width: 100%;
  height: 30px;
  color: #464646;
}

.share-footer-buttons-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
}

.share-button {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  color: #fbfbfb;
  width: 130px;
  height: 40px;
  border-radius: 3px;
  cursor: pointer;
}

.share-button-facebook {
  background-color: #3b5998;
}
.share-button-twitter {
  background-color: #00acee;
}
.share-button-copy {
  background-color: #c7c7c7;
}
