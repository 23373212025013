:root {
  --header-height: 60px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.uploadedPhoto {
  width: 900px;
  height: 700px;
}

.header {
  background-image: linear-gradient(to right, rgba(37, 37, 37, 0), rgba(37, 37, 37, 1)), url('../images/bg_pattern.svg');
  background-size: cover;
  background-color: #252525;
  background-position: -50%;
  height: var(--header-height);
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
}

.header .phototopia-logo,
.header .fb-like {
  margin-left: 25px;
  margin-right: 25px;
}

.image-filter {
  width: 96px;
  height: 96px;
  position: relative;
  cursor: pointer;
  border: 3px solid transparent;
}

.image-filter.active {
  border-width: 3px;
  border-style: solid;
  border-radius: 5px;
  box-sizing: content-box;
  border-image-slice: 1;
  border-image-source: linear-gradient(45deg, #fcaf40, #ee2b7b);
}

.image-filter img {
  width: 100%;
  height: 100%;
}

.filter-text {
  font-family: Roboto;
  font-weight: normal;
  font-size: 12px;

  position: absolute;
  text-align: center;
  bottom: 10px;
  left: 0;
  width: 100%;
  color: white;
}

.image-filter .shadow {
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, transparent, transparent 70%, #000 105%);
  position: absolute;
  top: 0;
  left: 0;
}


.image-controls {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-container {
  width: 100%;
}

.slider-container+.slider-container {
  margin-top: 15px;
}

.slider-meta {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #FFF;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 5px;
  background: #252525;
  outline: none;
  opacity: 1;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 5px;
}
.slider.colored {
  background: linear-gradient(to right, #02fb8d, #00aeda 100%, #252525 100%);
}

.slider:hover {
  opacity: 0.9;
  border-radius: 5px;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 25px;
  background: #FFF;
  cursor: pointer;
}

::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 25px;
  background: #FFF;
  cursor: pointer;
}

.container-scrollbar {
  margin-right: 7px;
}
.container-scrollbar:hover {
  margin-right: 0px;
}

.container-scrollbar::-webkit-scrollbar {
  width: 0px;
}

.container-scrollbar:hover::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.container-scrollbar::-webkit-scrollbar-track {
  background: #363636;
  border-radius: 25px;
}

/* Handle */
.container-scrollbar::-webkit-scrollbar-thumb {
  background: #595959;
  border-radius: 7px;
}

/* Handle on hover */
.container-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#image-upload-area {
  width: 350px;
  height: 350px;
  background: url('../images/drop_area_bg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  border-radius: 5px;
}
.image-upload-label {
  color: #737373;
  font-size: 16px;
}

#editor-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

#editor-container > #canvas-container {
  position: relative;
  top: 0px;
  left: 0px;
  max-width: 100%;
  max-height: 60vh;
  box-shadow: 5px 5px 10px -5px black;
}

#canvas-container > canvas {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.control-button {
  color: #FFF;
  width: 100px;
  padding-left: 8px;
  padding-right: 8px;
  height: 40px;
  border-radius: 25px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  font-weight: 600;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  user-select: none;
  /* font-size: 0.9em; */
}

.cyan-button {
  background: linear-gradient(to right, #02fd93 0%, #20b3f8 100%);
  user-select: none;
}
.magenta-button {
  background: linear-gradient(to right,rgba(129, 0, 251, 1), rgba(225, 0, 255, 1));
  user-select: none;
}

.gray-button {
  width: 90px;
  height: 30px;
  line-height: 30px;
  font-size: 0.75em;
  color: #adadad;
  background: linear-gradient(to right, #eeeeee 0%, #ffffff 100%);;
  border: 2px solid #adadad;
  user-select: none;
}

.image-upload-label + .upload-button {
  margin-top:15px;
}

.image-controls-container + .upload-button {
  margin-top: 35px;
}

.image-upload-input {
  width: 0px;
  height: 0px;
}

#photo-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 70%;
  margin: 20px 0;
}

#original-image {
  display: none;
}

#controls-dimmer {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.copied-notification {
  z-index:10001;
  width:100px;
  line-height:40px;
  bottom:15vh;
  background-color:white;
  text-align:center;
  position:absolute;
  border-radius: 0px 0px 5px 5px;
  color: #686868;
  box-shadow: 10px 10px 7px 0 rgba(37, 37, 37, 0.75);      
}

.feedback {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  font-size: medium;
  text-align: center;
  background: linear-gradient(to top right, #d6d6d6, #f6f6f6); 
  color: #737373;
  height: 70%;
  margin: 25px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: gray 1px 5px 10px;
}

.feedback-cta {
  width: auto;
}

.feedback-close-button::before {
  content: 'x';
  color: #444;
  font-weight: bold;
}

.feedback-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 0px 6px 2px 6px;
  background-color: #ddd;
  border-radius: 50%;
  cursor: pointer;
}

.feedback-close-button:hover {
  background-color: #ccc;
}